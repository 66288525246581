import React, { FC } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import { Box, Circle, Container } from 'styles/globalStyle'
import { Text, RichText, Grid, Title, Button, Icons } from 'components/theme'
import { ArticlesTypes } from 'types/articlesTypes'
import { GatsbyImage } from 'gatsby-plugin-image'
import SEO from 'components/SEO'

type ArticlesTemplateParams = {
  data: {
    prismicArticles: {
      data: ArticlesTypes
    }
  }
}

const ArticlesTemplate: FC<ArticlesTemplateParams> = ({ data }) => {
  const {
    title_meta,
    description_meta,
    title,
    description,
    twitter_link,
    facebook_link,
    content,
    grid,
    grid_columns,
  } = data.prismicArticles.data
  const copyClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
  }

  return (
    <Layout>
      <SEO title={title_meta.text} description={description_meta.text} />
      <Container mt="6rem" mb="4rem">
        <Grid
          columns={['1fr', '', '', '', '1fr 0.5fr', '1fr 0.35fr']}
          spacing={['2rem', '3rem', '4rem', '', '', '6rem']}
          alignItems="flex-start"
        >
          <Box>
            <Title size="lg" mb="1.5rem">
              {title.text}
            </Title>
            <Text size="xl" mb="4rem">
              {description.text}
            </Text>
            {/* <Flex mb={['0', '', '', '', '4rem']}>
              <Button
                type="light"
                size="md"
                mr="0.75rem"
                onClick={copyClipboard}
              >
                <Icons
                  as="span"
                  name="copy"
                  width="1rem"
                  height="1rem"
                  mr="0.5rem"
                />
                Copier le lien
              </Button>
              {twitter_link.url && (
                <Button
                  type="light"
                  width="2.5rem"
                  size="md"
                  mr="0.75rem"
                  href={twitter_link.url}
                  padding="0"
                >
                  <Icons
                    as="span"
                    name="twitter"
                    width="1.25rem"
                    height="1.25rem"
                    color="gray.100"
                  />
                </Button>
              )}
              {facebook_link.url && (
                <Button
                  type="light"
                  width="2.5rem"
                  size="md"
                  href={facebook_link.url}
                  padding="0"
                >
                  <Icons
                    as="span"
                    name="fb"
                    width="1.25rem"
                    height="1.25rem"
                    color="gray.100"
                  />
                </Button>
              )}
            </Flex> */}
            <Box pb={['2rem', '', '', '4rem']}>
              {grid.length > 0 && grid[0].item_title.text && (
                <Grid
                  columns={[
                    '1fr',
                    'repeat(2, 1fr)',
                    '',
                    '',
                    '',
                    `repeat(${grid_columns || 3}, 1fr)`,
                  ]}
                  spacing="3rem"
                  mb="4rem"
                >
                  {grid.map((item, idx) => (
                    <Box key={idx}>
                      <GatsbyImage
                        alt={item.item_image.alt}
                        image={item.item_image.gatsbyImageData}
                      />
                      <Text as="h2" size="xl" my="2rem" color="gray.900">
                        {item.item_title.text}
                      </Text>
                      <RichText content={item.item_content} isGrid />
                    </Box>
                  ))}
                </Grid>
              )}
              <RichText content={content} />
            </Box>
          </Box>
          <Box
            bg="primary.600"
            p="2rem 2rem 2.5rem"
            borderRadius="1rem"
            position="sticky"
            top="10rem"
          >
            <Circle bg="primary.50" width="3.5rem" height="3.5rem">
              <Icons
                name="headphones"
                width="1.25rem"
                height="1.25rem"
                color="primary.600"
              />
            </Circle>
            <Title size="xs" as="h3" color="white" mt="2rem">
              Testez votre ouïe dès maintenant
            </Title>
            <Text color="white" mt="0.75rem">
              À moins de tester son audition, il est impossible de savoir si
              l’on est atteint d’hypoacousie. Audiplus vous propose un rapide
              test auditif à faire en ligne, depuis chez vous !
            </Text>
            <Button
              type="borderWhite"
              mt="2rem"
              size="xl"
              width="calc(100% - 2.5rem)"
              href="https://www.testyourhearing.com/audiplus"
              target="_blank"
            >
              Je fais le test en ligne
            </Button>
            <Button
              type="white"
              mt="0.75rem"
              size="xl"
              width="calc(100% - 2.5rem)"
              to="/appointment"
            >
              Je prends rendez-vous
            </Button>
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($uid: String) {
    prismicArticles(uid: { eq: $uid }) {
      data {
        ...PrismicArticlesDataTypeFragment
      }
    }
  }
`

export default ArticlesTemplate
